
import { useEffect, useState } from 'react';
import './App.css';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

function App() {


  const [pressed, setPressed] = useState(false);

  const [scale, setScale] = useState(1);
  const [screenWidt, setScreenWidt] = useState(1);
  useEffect(() => {
    const updateScale = () => {
      const currentWidth = window.innerWidth;
      let scaleFactor = 0;
      setScreenWidt(currentWidth)
      if (currentWidth > 1124) {
        scaleFactor = currentWidth / 1400; // Calculate the scale factor based on the current width
      }
      else {
        scaleFactor = 1
      }
      setScale(scaleFactor); // Update the scale state
    };

    // Call the updateScale function on initial render and when the window is resized
    updateScale();
    window.addEventListener('resize', updateScale);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, []);


  useEffect(() => {

    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => {
      console.log('begin', to, element);
    });

    // Registering the 'end' event and logging it to the console when triggered.
    Events.scrollEvent.register('end', (to, element) => {
      console.log('end', to, element);
    });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  return (
    <div className="main">
      <div className="main-container">

        <header>
          <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <img src={require("./images/baca8d6452f2570bf9a7b10b3f6a4d625a698c8c.png")} className='logo' />
            <div className='t28px500'>
              SPY<br />DRONES
            </div>
          </div>
          <div className='headerRight'>
            <Link
              style={{ marginRight: 80 }}
              to="aboutUsId"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >ПРО НАС</Link>
            <Link
              style={{ marginRight: 80 }}
              to="dronesId"
              spy={true}
              smooth={true}
              offset={-40}
              duration={500}
            >ДРОНИ</Link>

            <Link
              className="HeaderConUS"
              to="ConnectToUs"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              ЗВ’ЯЗАТИСЯ З НАМИ
            </Link>
          </div>

        </header>

        <div className='underHead'>
          <div className='underHeadRight'>
            адаптивні, захищені<br /> та стійкі до РЕБ
          </div>
          <div>
            З  <span className='spyDrones'>SPY DRONES</span> -<br />
            перемога в кожному польоті
          </div>

        </div>

        <img src={require("./images/8d14f50d-4a3b-4715-9da8-74f749e9952f.png")} style={{ marginTop: 90 }} className='BigImage HeaderBigImg' />
        <img src={require("./images/unsplash_ZlkRrzJl20Q.png")} style={{ marginTop: 30 }} className='SmallImage' />

        <div className='AboutUS' id="aboutUsId">
          <span>
            <img src={require("./images/0f389877-3562-48a4-b6db-dbda5ed1f1e6.png")} style={{ height: 30, width: 30 }} className='BigImage' />
            <img src={require("./images/0f389877-3562-48a4-b6db-dbda5ed1f1e6.png")} style={{ height: 30, width: 30 }} className='BigImage' />
            <img src={require("./images/0f389877-3562-48a4-b6db-dbda5ed1f1e6.png")} style={{ height: 30, width: 30, marginRight: 10 }} className='' />
            ПРО НАС
          </span>
          <p>
            У серці нашої компанії лежить місія зробити високотехнологічні дрони доступними для кожного. З моменту нашого заснування ми пройшли шлях інтенсивного росту та розвитку, а також ми постійно вдосконалюємо наші дрони, впроваджуючи останні технологічні розробки.
            Наш успіх - це результат праці талановитої команди фахівців, які віддані своїй справі. Наші інженери, дизайнери та фахівці з обслуговування завжди готові надавати персоналізовану та професійну допомогу
          </p>
        </div>


        <div className='detailsWrapper' style={scale < 1 ? { height: 622 * scale } : {}}>
          <div className='Drones' id="dronesId" style={scale < 1 ? { transform: `scale(${scale})` } : {}}>
            <div className='drone droneLeft droneG'>
              <img src={require("./images/b42a9d5b-9e13-4bc0-a551-c9ab3c3f690b.png")} className='BGFolderImg' />
              <img src={require("./images/2e8e5acdce4eb957423badde50db24aad1dfcf67.png")} className='droneImg' />

              <img src={require("./images/Vector20.png")} className='BGFolderImgBig' />
              <h3 className='droneDetailInfo'>
                Рама - карбон 10”<br />
                Навантаження - від 2 до 4 кг<br />
                Двигун - 900KV<br />
                FC - F405 MK2 V2 (MPU6000) F55 BLS ESC<br />
                VTX - не нижче 2.5W 5.8G<br />
                Антена - Cherry або Pagoda 150 мм<br />
                Gemfan 1050-3 for Cinelifter/Glassfiber nylon 2CW+2CCW<br />
                Камера - Foxeer 3 mini night<br />
                Приймач - ELRS ES915 RX dual<br />
                Батарея - Li-on 6S2P 8400 mAh 90A Molicel<br />
              </h3>

              <h2 className='droneDetailSub' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Додаткове обладнання:
              </h2>
              <h3 className='droneDetailSubInfo' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                стропи для батареї<br />
                Antispark фільтр<br />
                Scrambler аналогового відеосигналу<br />
                система ініціації з двоступеневим запобіжником<br />
              </h3>

              {/* <h2 className='droneBaseComp' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Базова комплектація
              </h2>
              <h3 className='droneBaseCompDet' style={{ position: 'absolute', lineHeight: 1.8 }}>
                Ударний БпЛА камікадзе “U10T”<br />
                Плата ініціалізаці
              </h3>

              <h2 className='dronePrice' style={{ position: 'absolute', }}>
                Ціна
              </h2>
              <h3 className='dronePriceList' style={{ position: 'absolute', lineHeight: 1.8 }}>
                57 200 грн/шт<br />
                1 200 грн/шт
              </h3> */}
              <table className='smallDroneTable'>
                <thead>
                  <td className='tdLeft'>Базова комплектація</td>
                  <td className='tdRight'>Ціна</td>
                </thead>
                <tbody>
                  <tr>
                    <td className='tdLeft'>Ударний БпЛА камікадзе “U10T”</td>
                    <td className='tdRight'>57 200 грн/шт</td>
                  </tr>
                  <tr>
                    <td className='tdLeft'>Плата ініціалізаці</td>
                    <td className='tdRight'>1 200 грн/шт</td>
                  </tr>
                </tbody>
              </table>

              <h4 className='dronePriceTotal' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Всього:
              </h4>
              <h4 className='dronePriceHrn' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                58 400 грн
              </h4>

              <span>Ударний БпЛА<br />камікадзе</span>
              <h1>Модель U10T</h1>
            </div>
            <div className='drone droneRight droneGrey'>
              <img src={require("./images/6c71b124-fb21-4fa6-b395-4a7224c81726.png")} className='BGFolderImg' />
              <img src={require("./images/2e8e5acdce4eb957423badde50db24aad1dfcf67.png")} className='droneImg' />

              <img src={require("./images/Vector22.png")} className='BGFolderImgBig' />
              <h3 className='droneDetailInfo'>
                Рама - карбон 7”<br />
                Навантаження - від 1.3 до 1.5 кг<br />
                Двигун - 2806 1300KV<br />
                HQProp - 7x4x4 7” 3-blade PC<br />
                Stack - F405 MK2 V2 (MPU6000) F55 BLS ESC<br />
                VTX - не нижче 2.5W 5.8G<br />
                Антена - Cherry або Pagoda 150 mm<br />
                Термальна камера<br />
                TIC 374 5.8/ELRS<br />
                Спектральний діапазон - 8-14мкм<br />
                Частота кадрів - 50 Гц<br />
                Розширення - 384х288 мегапікселів<br />
                Приймач - ELRS ES915 RX dual<br />
                Батарея - Li-on 6S3P 8400 mAh 90A Molicel<br />
              </h3>

              <h2 className='droneDetailSub' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Додаткове обладнання:
              </h2>
              <h3 className='droneDetailSubInfo' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                стропи для батареї<br />
                Antispark фільтр<br />
                Scrambler аналогового відеосигналу<br />
                система ініціації з двоступеневим запобіжником<br />
              </h3>

              {/* <h2 className='droneBaseComp' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Базова комплектація
              </h2>
              <h3 className='droneBaseCompDet' style={{ position: 'absolute', lineHeight: 1.8 }}>
                Ударний БпЛА камікадзе “U17T”
              </h3>

              <h2 className='dronePrice' style={{ position: 'absolute', }}>
                Ціна
              </h2>
              <h3 className='dronePriceList' style={{ position: 'absolute', lineHeight: 1.8 }}>
                49 000грн/шт
              </h3> */}

              <table className='smallDroneTable'>
                <thead>
                  <td className='tdLeft'>Базова комплектація</td>
                  <td className='tdRight'>Ціна</td>
                </thead>
                <tbody>
                  <tr>
                    <td className='tdLeft'>Ударний БпЛА камікадзе “U7T”</td>
                    <td className='tdRight'>49 000грн/шт</td>
                  </tr>

                </tbody>
              </table>

              <h4 className='dronePriceTotal' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Всього:
              </h4>
              <h4 className='dronePriceHrn' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                49 000 грн
              </h4>


              <span>Ударний БпЛА<br />камікадзе</span>
              <h1>Модель U7T</h1>
            </div>
          </div>
        </div>
        <div className='detailsWrapper' style={scale < 1 ? { height: 722 * scale } : {}}>
          <div className='Drones' style={scale < 1 ? { transform: `scale(${scale})` } : {}}>
            <div className='drone droneLeft droneGrey'>
              <img src={require("./images/6c71b124-fb21-4fa6-b395-4a7224c81726.png")} className='BGFolderImg' />
              <img src={require("./images/2e8e5acdce4eb957423badde50db24aad1dfcf67.png")} className='droneImg' />

              <img src={require("./images/Vector22.png")} className='BGFolderImgBig' />
              <h3 className='droneDetailInfo'>
              Рама - карбон 10”<br/>
              Навантаження - від 2 до 4 кг<br/>
              Двигун - 900KV<br/>
              FC - F405 MK2 V2 (MPU6000) F55 BLS ESC<br/>
              VTX - не нижче 2.5W 5.8G<br/>
              Антена - Cherry або Pagoda 150 мм<br/>
              Gemfan 1050-3 for Cinelifter/Glassfiber nylon 2CW+2CCW<br/>
              Камера - Foxeer 3 mini night<br/>
              Приймач - ELRS ES915 RX dual<br/>
              Батарея - Li-on 6S2P 8400 mAh 90A Molicel<br/>
              </h3>

              <h2 className='droneDetailSub' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Додаткове обладнання:
              </h2>
              <h3 className='droneDetailSubInfo' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                стропи для батареї<br />
                Antispark фільтр<br />
                Scrambler аналогового відеосигналу<br />
                система ініціації з двоступеневим запобіжником
              </h3>

              {/* <h2 className='droneBaseComp' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Базова комплектація
              </h2>
              <h3 className='droneBaseCompDet' style={{ position: 'absolute', lineHeight: 1.8 }}>
                Ударний БпЛА камікадзе “U10.1”
              </h3>

              <h2 className='dronePrice' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Ціна
              </h2>
              <h3 className='dronePriceList' style={{ position: 'absolute', lineHeight: 1.8 }}>
                23 000грн/шт
              </h3> */}

              <table className='smallDroneTable'>
                <thead>
                  <td className='tdLeft'>Базова комплектація</td>
                  <td className='tdRight'>Ціна</td>
                </thead>
                <tbody>
                  <tr>
                    <td className='tdLeft'>Ударний БпЛА камікадзе “U10.1”</td>
                    <td className='tdRight'>23 000грн/шт</td>
                  </tr>
                </tbody>
              </table>


              <h4 className='dronePriceTotal' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Всього:
              </h4>
              <h4 className='dronePriceHrn' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
              23 000 грн
              </h4>

              <span>Ударний БпЛА<br />камікадзе</span>
              <h1>Модель U10.1</h1>
            </div>
            <div className='drone droneRight droneG'>
              <img src={require("./images/b42a9d5b-9e13-4bc0-a551-c9ab3c3f690b.png")} className='BGFolderImg' />
              <img src={require("./images/2e8e5acdce4eb957423badde50db24aad1dfcf67.png")} className='droneImg' />


              <img src={require("./images/Vector23.png")} className='BGFolderImgBig LastDroneImg' />
              <h2 className='TTH'>Тактико-технічні характеристики</h2>
              <h3 className='droneDetailInfoTTh'>
                Тактичний радіус дії - 7 км<br />
                Час розгортання - 5 хв<br />
                Максимальна злітна маса - 3.5 кг<br />
                Злітна маса з корисним навантаженням - 3 кг<br />
                Маса БпЛА без батареї - 650 г<br />
                Тип силової установки - електрична<br />
                К-сть акумуляторів - 1 шт<br />
                Вага акумуляторів - 835 г<br />
                Напруга живлення - 25В<br />
                Максимальна тривалість польоту без навантаження - 12 хв<br />
                Максимальна тривалість польоту з навантаженням - 8 хв<br />
                Практична стеля - 500 м<br />
                Макс. швидкість - 120 км/год<br />
                Крейсерська швидкість - 70 км/год<br />
                Стійксіть до РЕБ та РЕР - присутня<br />
                Макс. швидкість підйому - 8 м/сек<br />
                Макс. швидкість спуску - 8 м/сек<br />
                Можливість встановлення скиду (одинарний заряд до 1.5 кг без керування підривом)<br />
                Час зарядки - до 90 хв<br />
              </h3>

              <h2 className='droneDetailSubTTh' >
                Склад БпАК “U7.1”
              </h2>
              <h3 className='droneDetailInfoTTh' >
                БпЛА камікадзе<br />
                Пульт керування<br />
                Станція ретрансляції<br />
                Акумулятор 6S2P<br />
                Зарядна станція для акумуляторів<br />
                FPV окуляри-шлем<br />
                Базовий інструмент<br />
                Кількість БпЛА 25, 50, 100<br />
                Бойове напруження - до 20 вильотів на добу<br />
                Камера Ratel2 1200TLV Camera<br />
                Максимальна дальність польоту - 12 км<br />
                Діапазон частот БпАК: управління 850-950 МГц,<br />
                телеметрія 850-950 МГц, аналогове відео 5.8 ГГц
              </h3>

              {/* <h2 className='droneBaseComp' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
              Базова комплектація
            </h2>
            <h3 className='droneBaseCompDet' style={{ position: 'absolute', lineHeight: 1.8 }}>
              Ударний БпЛА камікадзе “U10T”<br />
              Плата ініціалізаці
            </h3>

            <h2 className='dronePrice' style={{ position: 'absolute', }}>
              Ціна
            </h2>
            <h3 className='dronePriceList' style={{ position: 'absolute', lineHeight: 1.8 }}>
              57 200 грн/шт<br />
              1 200 грн/шт
            </h3> */}

              <div className='bigTableWrapper'>
                <table className='bigDroneTable'>
                  <thead>
                    <td className='tdLeft'>Базова комплектація БпАК</td>
                    <td className='tdRight'></td>
                  </thead>
                  <tbody>
                  {/* 100 шт
100 шт
1 шт
1 шт
1 шт */}
                    <tr>
                      <td className='tdLeft'>БпЛА “U7.1”</td>
                      <td className='tdRight'>100 шт</td>
                    </tr>
                    <tr>
                      <td className='tdLeft'>“U7.1” Акумулятор 6S2P</td>
                      <td className='tdRight'>100 шт</td>
                    </tr>
                    <tr>
                      <td className='tdLeft'>Пульт Radiomaster TX 12</td>
                      <td className='tdRight'>1 шт</td>
                    </tr>
                    <tr>
                      <td className='tdLeft'>FPV окуляри-шолом SKYZONE Cobra XV2</td>
                      <td className='tdRight'>1 шт</td>
                    </tr>
                    <tr>
                      <td className='tdLeft'>Комплект запасних частин і приладдя (ЗІП)</td>
                      <td className='tdRight'>1 шт</td>
                    </tr>
                  </tbody>
                </table>

                <table className='bigDroneTable secBigDroneTable'>
                  <thead>
                    <td className='tdLeft'>Додаткова комплектація</td>
                    <td className='tdRight'></td>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='tdLeft'>Станція ретрансляції “FPV Re-Link”</td>
                      <td className='tdRight'>1 шт</td>
                    </tr>
                    <tr>
                      <td className='tdLeft'>Бойова частина з платою ініціалізації</td>
                      <td className='tdRight'>100 шт</td>
                    </tr>
                  </tbody>
                </table>

                <table className='bigDroneTable secBigDroneTable'>
                  <thead>
                    <td className='tdLeft'>Базова комплектація</td>
                    <td className='tdRight'>Ціна</td>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='tdLeft'>Безпілотний авіаційний комплекс “U7.1” (БпАК “U7.1”)</td>
                      <td className='tdRight'>2 765 000грн/шт</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <h4 className='dronePriceTotalBig' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                Всього:
              </h4>
              <h4 className='bigDroneTableBigHrn' style={screenWidt > 1114 ? { position: 'absolute' }:{}}>
                2 765 000грн
              </h4>

              <span>Ударний БпЛА<br />камікадзе</span>
              <h1 className='marBottom20'>Модель U7.1</h1>
            </div>
          </div>
        </div>

        <div className='animDiv'>
          <span>SPY DRONES</span>
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <span>SPY DRONES</span>
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <span>SPY DRONES</span>
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
        </div>

        <div className='animDiv animDiv2' >
          <span>SPY DRONES</span>
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <span>SPY DRONES</span>
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <span>SPY DRONES</span>
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
          <img src={require("./images/a74af409-7f58-4a3a-a833-0af6099c3bf3.png")} className='amimArrow' />
        </div>

        <div className='detailsWrapper'>
          <div className='details' style={scale < 1 ? { transform: `scale(${scale})` } : {}}>
            <div className='detailsLeft'>
              <div className='detailsAdaptive'>
                <h1 className='detailsNum'>001</h1>
                <h1 className='detailsName'>адаптивність </h1>
                <img src={require("./images/e9325ec8-85c9-4856-ae7d-41ede2bcd843.png")} className='detailsLeftImg' />
              </div>
              <div className='detailsHighSec'>
                <h1 className='detailsNum'>002</h1>
                <h1 className='detailsName'>висока якість</h1>
                <img src={require("./images/71fa3264-0eeb-43f8-94b7-3528a2bd7ac2.png")} className='' />
              </div>
            </div>
            <div className='detailsRight'>
              <div className='detailsLine'>
                <div className='detailsHigh'>
                  <h1 className='detailsNum'>002</h1>
                  <h1 className='detailsName'>висока якість</h1>
                  <img src={require("./images/71fa3264-0eeb-43f8-94b7-3528a2bd7ac2.png")} className='' />
                </div>
                <div className='detailsNadii'>
                  <h1 className='detailsNum'>003</h1>
                  <h1 className='detailsName'>надійність</h1>
                </div>
              </div>
              <div className='detailsLine detailsLineMarTop'>
                <div className='detailsErgono'>
                  <h1 className='detailsNum'>004</h1>
                  <h1 className='detailsName'>Ергономіка</h1>
                </div>
                <div className='detailsEfi'>
                  <h1 className='detailsNum'>005</h1>
                  <h1 className='detailsName'>Ефективність</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='ContacktUS' id="ConnectToUs">
          <span>
            <img src={require("./images/0f389877-3562-48a4-b6db-dbda5ed1f1e6.png")} style={{ height: 30, width: 30 }} className='BigImage' />
            <img src={require("./images/0f389877-3562-48a4-b6db-dbda5ed1f1e6.png")} style={{ height: 30, width: 30 }} className='BigImage' />
            <img src={require("./images/0f389877-3562-48a4-b6db-dbda5ed1f1e6.png")} style={{ height: 30, width: 30, marginRight: 10 }} className='' />
            дізнатися деталі
          </span>
          <p>
            <h2 className='ContactUsText'>З’вяжіться з нами, щоб дізнатися про деталі, ціни або<br className='BigImage' /> співпрацю для майбутньої повітряної підтримки</h2>
            <div className='ContactUsInput'>
              <input placeholder="+ 380(96)000-00-00"></input>
              <button class="ContactButton" disabled={pressed} style={pressed ? { background: "#fff", color: "#0C3934", border: "1px solid #0C3934" } : {}} onClick={() => { setPressed(true); }}>
                {!pressed ? "Зв’язатися з нами" : "Дякуємо за звернення"}
              </button>
            </div>
          </p>
        </div>

        <footer>
          <div className='footerLeft'>
            <div className='footerLogo'>
              <img src={require("./images/baca8d6452f2570bf9a7b10b3f6a4d625a698c8c.png")} className='logo' />
              <div className='t28px500' style={{ color: "#F8F8F8" }}>
                SPY<br />DRONES
              </div>
            </div>
            <div className='footerlinks'>
              {/* <a href="tel:+380669278636" className='footerphone firstFphone'>+38 066 927 86 36</a>
              <a href="tel:+380984270021" className='footerphone'> +38 098 427 00 21</a> */}
              <a href="mailto:spydronesua@gmail.com" className='footermail'>spydronesua@gmail.com</a>
            </div>
          </div>

          <img src={require("./images/2b800ba3-0468-46fa-9418-3eb2b3e96ff1.png")} className='footerImg' />
          <div className='footerRight'>
            <div className='support'>
              {/* <a href="tel:+380000000000">+380 00 000 00 00</a>
              <span>технічка підтримка</span>
              <a href='https://telegram.org/' className='telegramWrapper'>
                <img src={require("./images/3e983a31-c834-477e-aad4-29467be87c79.png")} className='telegram' />
              </a> */}
            </div>
            <div className='footerInfo'>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* <span>ТОВ “ЮВІТЕК”</span>
                <span>ЄДРПОУ 39440158</span>
                <span>01032, Київська обл. м. Київ</span>
                <span>вул. Саксаганського, 119 </span> */}

              </div>
              <span className='copyrigtingText' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img src={require("./images/532422ac-b761-4966-bc91-beb8d9f6c219.png")} className='copyrigting' /> 2023 SPY DRONES</span>
            </div>
          </div>
        </footer>
      </div>
    </div >
  );
}

export default App;
